import React from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()

  return (
    <ChatComponent
      name="Gewerbeanmeldung Assistent"
      pageTitle="Convaise - Gewerbeanmeldung"
      // homepage="#"
      // token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // direct line
      // courier (prod)
      // token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODM4ODU5MjMuNzUxNzI2LCJleHAiOjIzMTQ2MDU5MjMuNzUxNzI2LCJib3RJZCI6IjNibzI1cHNra3M4aHc3NCIsImlzcyI6ImNvbnZhaXNlIiwiYXVkIjoiY29udmFpc2UtY291cmllciJ9.WW1t7EIhI-YIxIoQXX-QguP9mbXzzQbUACQWBBHiWUW-hNyOIeoDwOWiGrhbqY5daydFCEKOjg2k2PHS-07eK6vaHGzsLmCcwiso1Ttp2pQccIXKIb6a4g_z5BBjdubdpkSLbfkWYf9MdyWx0LM8ExLFKoPSLnD8kpedR23aOh1t2UC9CaxvBaxXARpX5lRI5J1q1Hkw7hMtlspslVmbtsKVz30wRpcirbesBQ0x4M2hOAIjny_IeLKdO3WOXVyrYl9C5rt_Ugh3UoD9jrKN4qnIT4_cZosBSTgezTg-BWyk1ay0m8R__5A_zmT7kP_MH3c8rw9tuRW_KKeWjHGobqa-VulKHFyU_5ELQ_zeTd8pewVTz6OI9HkiA9CRaYm_9beXATY2lbMS4N5bbaQulZm_IyHaAYcW9tZApNoJGF1rqPQ12VzTx1xJLLQzeypvi6faY1i4u2x0CUFdQJCOJVNK1CxBWd26N4y_q0ii3wKSdofjNBXuTMGAEKvrR3E9d56zTRo2uxvtiJYasAfESIgX3luoSObDR95tDV9bzQrbjiFRt5Nds7-Daw-nMFFPmpnyOlH8ghD098dqVba3n6Socv0_bb3v_HKmevx51hBxEbyMX0LLSnnpGypVHn2D_uIqIIY_SOKxvfEFNp3GcfTL4W8s_ykBU8i0LNoXwZo"
      // courier (dev + local)
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODM4OTYzNjUuODgzNzQ3LCJleHAiOjIzMTQ2MTYzNjUuODgzNzQ3LCJib3RJZCI6IjNibzI1cHNra3M4aHc3NCIsImlzcyI6ImNvbnZhaXNlIiwiYXVkIjoiY29udmFpc2UtY291cmllciJ9.N0nhJ3qkJNjFGaPxBwpmmDWNVzarO3GnLWnlOKEXZnLzYqWq6BlyBIfldOBGWC7Y4DfwZASFytj1i-tG5i7NoDbkLEyvszDD6Eb2Myqz3_Aw4hoC7TcFNXaFfGJiItEB5YcRhEZfgQ01NWIjpT7Ny_LWaaR-iky2Ok_70mJBm8Rd-suTWrpoEo0kvvgfXzwZt0BguE-p8utiURN15UJHiOmsm9CepafxpZSkHaUMjC5JWPTjVbPjjLvnKJfdJGhjm2sYHmqolLM7jzmZZjv9LtcvHa63rqZRE4cBdoMaxqYB-bhwi7AfrrS02ZbxJZemlfVWzZUEX0GH9D3wkgAI27KGNBIL2S4aVUkrNb3-zH6lfCYjAJt-bf64IEKzAurOVlX62F9cXDR3lWitAZzMCbvK4w0k8NL96tT-JTclLqKwfNnTMZEs-Mv9SjzeD5w3d2lC8J3ZrvAgyyXea4Chss1n0sg9jBlSH-jg9zqkdPkpKFYwFa4ZvmDZad1RoVdUOnXQOsXtBG7Pe9FgKkSeRdUvY3S2J20WpO_9h8OAJW59giLejafWuOlHpPTIG1ztEqLgHS2PBsSawNPZf_Sz9EF3LTANz_913fc1sSmLwtk-Dvhwgji2GmdGwoRswsKOI2c_kr1Z7RCgYb92zHtrbscK2-qyFwTw0GYJxNRrizQ"
      botId="3bo25pskks8hw74"
      avatar={false}
      avatarInitials={false}
      logo="https://static.convaise.com/convaise-logo.svg"
      // domain="https://europe.directline.botframework.com/v3/directline"
      // domain="http://localhost:8000/v3/directline"
      domain="https://courier.convaise.com/frontend/v3/directline"
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
        botAvatarBackgroundColor: "#fff",
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      // styleCustomizations={{
      //   "--button-blue": "#551285",
      //   "--button-blue-light": "#77419D",
      //   "--button-orange": "rgba(255, 166, 0, 1)",
      //   "--button-orange-light": "rgb(255, 182, 46)",
      // }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
